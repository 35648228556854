import SignaturePad from 'react-signature-pad-wrapper';
import React, { useRef, useState, useEffect, ChangeEvent, MutableRefObject } from 'react';

import {
  Box,
  Button,
  Dialog,
  useTheme,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import { CroppedCanvas } from 'src/@types/documents/types';
import { setSignImage, setFinalSignSize } from 'src/redux/slices/signaturePadReqModel';

import INK_COLOR from 'src/sections/forms/elements/signature-utils/inkColor';
import cropSignatureCanvas from 'src/sections/forms/elements/signature-utils/cropSignatureCanvas';
import writeNameOnTheCanvas from 'src/sections/forms/elements/signature-utils/writeNameOnTheCanvas';

type DocumentSignaturePadDialogProps = {
  isSignaturePadDialogOpen: boolean;
  setIsSignaturePadDialogOpen: (isSignaturePadDialogOpen: boolean) => void;
};

const DocumentSignaturePadDialog: React.FC<DocumentSignaturePadDialogProps> = ({
  isSignaturePadDialogOpen,
  setIsSignaturePadDialogOpen,
}) => {
  const { t } = useLocales();
  const [addedEventListener, setAddedEventListener] = useState(false);
  const [isEmptySignature, setIsEmptySignature] = useState(true);
  const canvas = useRef() as MutableRefObject<any>;
  const theme = useTheme();
  const [name, setName] = useState<string | undefined>(undefined);
  const [surname, setSurname] = useState<string | undefined>(undefined);

  const handleClose = () => {
    setIsSignaturePadDialogOpen(false);
  };

  const handleClear = () => {
    canvas.current.clear();
    setIsEmptySignature(true);
  };

  const handleEndStroke = () => {
    setIsEmptySignature(canvas.current.isEmpty());
  };

  function calculateContentSize(canvas) {
    const ctx = canvas.getContext('2d');
    if (!ctx) return { width: 0, height: 0 };
  
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let minX = canvas.width;
    let minY = canvas.height; 
    let maxX = 0;
    let maxY = 0;
    let {data} = imageData;
  
    for (let y = 0; y < canvas.height; y++) {
      for (let x = 0; x < canvas.width; x++) {
        const alphaIndex = (y * canvas.width + x) * 4 + 3;
        if (data[alphaIndex] > 0) {
          if (x < minX) minX = x;
          if (y < minY) minY = y;
          if (x > maxX) maxX = x;
          if (y > maxY) maxY = y;
        }
      }
    }
  
    return {
      width: maxX - minX + 1,
      height: maxY - minY + 1,
      offsetX: minX,
      offsetY: minY
    };
  }

  function adjustCanvasSize(combinedCanvas, maxWidth = 200, maxHeight = 80) {
    const { width, height } = combinedCanvas;
    let newWidth = width;
    let newHeight = height;

    if (width > maxWidth || height > maxHeight) {
      const scaleRatio = Math.min(maxWidth / width, maxHeight / height);
      newWidth = Math.round(width * scaleRatio);
      newHeight = Math.round(height * scaleRatio);
    }
  
    //  original dimensions if they are within the limits
    return { newWidth, newHeight };
  }

  function combineNameWithSignature(nameCanvas, signatureCanvas) {
    const nameSize = calculateContentSize(nameCanvas);
    const signatureSize = calculateContentSize(signatureCanvas);

    const combinedWidth = nameSize.width + signatureSize.width;
    const combinedHeight = Math.max(nameCanvas.height, signatureCanvas.height);

    const combinedCanvas = document.createElement('canvas');
    combinedCanvas.width = combinedWidth;
    combinedCanvas.height = combinedHeight;

    const ctx = combinedCanvas.getContext('2d');
    if (ctx) {
      ctx.clearRect(0, 0, combinedCanvas.width, combinedCanvas.height);
    ctx.drawImage(nameCanvas, 0, combinedHeight / 2 - nameCanvas.height / 2);
    ctx.drawImage(signatureCanvas, nameCanvas.width, combinedHeight / 2 - signatureCanvas.height / 2); 
    }

    return combinedCanvas;
  }

  const handleContinue = () => {
    const untrimmedCanvas = canvas.current.signaturePad.canvas as HTMLCanvasElement;
    const croppedSignature = cropSignatureCanvas(untrimmedCanvas);
    const croppedName = writeNameOnTheCanvas(`${name} ${surname}`, croppedSignature.height);

    if (croppedName) {
      const combinedCanvas = combineNameWithSignature(croppedName, croppedSignature);
      const { newWidth, newHeight } = adjustCanvasSize(combinedCanvas);

      const newCanvas: CroppedCanvas = {
        image: combinedCanvas.toDataURL(),
        width: newWidth,
        height: newHeight,
      };

      setSignImage(newCanvas.image, newCanvas.width, newCanvas.height);
      setFinalSignSize(newWidth, newHeight);
    } else {
      const newCanvas: CroppedCanvas = {
        image: croppedSignature.toDataURL(),
        width: croppedSignature.width,
        height: croppedSignature.height,
      };

      setSignImage(newCanvas.image, newCanvas.width, newCanvas.height);
    }

    setIsSignaturePadDialogOpen(false);
  };

  useEffect(() => {
    if (name && !addedEventListener && canvas.current && canvas.current.signaturePad) {
      canvas.current.signaturePad.addEventListener('endStroke', handleEndStroke);

      setAddedEventListener(true);
    }
  }, [name]);

  return (
    <Dialog open={isSignaturePadDialogOpen} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>{t('forms.addSignatureToSign')}</DialogTitle>

      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>
          {' '}
          {t('documents.writeDrawClickContinue')}
        </DialogContentText>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 3,
            }}
          >
            <TextField
              fullWidth
              size="small"
              label={t('shared.fields.firstName')}
              value={name}
              variant="outlined"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
              id="9i2feut6"
            />

            <TextField
              fullWidth
              size="small"
              label={t('shared.fields.lastName')}
              value={surname}
              variant="outlined"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSurname(event.target.value);
              }}
            />
          </Box>

          <Box sx={{ border: `1px solid ${theme.palette.grey[300]}` }}>
            <SignaturePad
              ref={canvas}
              options={{
                minWidth: 2,
                maxWidth: 4,
                penColor: INK_COLOR,
              }}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          px: 3,
          pb: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'left',
            gap: 3,
          }}
        >
          <Button
            disabled={!name || !surname || isEmptySignature}
            variant="contained"
            onClick={handleContinue}
            id="b2pe340j"
          >
            {t('shared.buttons.continue')}
          </Button>

          <Button variant="outlined" onClick={handleClose} id="mz5nnxqa">
            {t('shared.buttons.cancel')}
          </Button>
        </Box>

        <Button disabled={isEmptySignature} variant="text" onClick={handleClear} id="3anq0rc3">
          {t('shared.buttons.clear')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentSignaturePadDialog;
