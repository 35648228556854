import * as pdf from 'react-pdf';
import React, { useState, useEffect } from 'react';
import { IDocument } from '@cyntler/react-doc-viewer';
import { Rnd, Position, DraggableData } from 'react-rnd';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, useTheme, IconButton, Typography } from '@mui/material';

import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

import { useSelector } from 'src/redux/store';
import { setFinalSignSize, setSignCoordinates } from 'src/redux/slices/signaturePadReqModel';

// ----------------------------------------------------------------------

const { Page, pdfjs, Document } = pdf;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export type PDFPageProps = {
  pageNumber: number;
  pdfPageWidth: number;
  pdfPageHeight: number;
  annotations: number[][];
};

type Size = {
  width: number;
  height: number;
};

type DocumentsViewerPDFRendererProps = {
  currentDocument: IDocument;
  wrapperSize: { width: number; height: number };
};

const DocumentsViewerPDFRenderer: React.FC<DocumentsViewerPDFRendererProps> = ({
  currentDocument,
  wrapperSize,
}) => {
  const { t } = useLocales();
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pdfPages, setPDFPages] = useState<PDFPageProps[]>([]);
  const [currentPDFPage, setCurrentPDFPage] = useState<PDFPageProps>({
    pageNumber: -1,
    pdfPageWidth: -1,
    pdfPageHeight: -1,
    annotations: [],
  });

  const [scale, setScale] = useState<number>(1);

  // -------- Signature and Position
  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');
  const { stamp, width, height } = useSelector((state) => state.signaturePadReqModel);
  const [stampPage, setStampPage] = useState(1);

  const [signaturePosition, setSignaturePosition] = useState<{ x: number; y: number }>({
    x: isMobile ? 10 : 100,
    y: 100,
  });
  const [signatureSize, setSignatureSize] = useState<Size>({
    width: width || 200,
    height: height || 60,
  });

  const handleDragStop = (event: any, data: DraggableData) => {
    const newPos = { x: Math.round(data.x / scale), y: Math.round(data.y / scale) };

    if (newPos.x !== signaturePosition.x || newPos.y !== signaturePosition.y) {
      setSignCoordinates(newPos.x, newPos.y, stampPage - 1);
      setSignaturePosition(newPos);
    }
    if(width && height){
      setFinalSignSize(width, height);
    }
    
    console.log('drag stop-width-', [width, height]);
    console.log('signatureSize-dragstop-', [signatureSize.width, signatureSize.height]);
  };

  const handleResizeStop = (ref: HTMLElement, position: Position) => {
    const newSize = {
      width: Math.round(ref.offsetWidth / scale),
      height: Math.round(ref.offsetHeight / scale),
    };
    const newPosition = { x: Math.round(position.x / scale), y: Math.round(position.y / scale) };

    setSignatureSize(newSize);
    setSignaturePosition({ x: newPosition.x, y: newPosition.y });

    setFinalSignSize(newSize.width, newSize.height);
    setSignCoordinates(newPosition.x, newPosition.y, stampPage - 1);
  };

  useEffect(() => {}, [scale]);

  useEffect(() => {
    // init values
    const initX = isMobile ? 10 : 100;
    const initY = 100;
    setSignCoordinates(initX, initY, stampPage - 1);
    setFinalSignSize(width || 200, height || 60);

    setStampPage(pageNumber);
  }, [stamp]);

  // -------- Signature and Position ----- end

  const onDocumentLoadSuccess = async (pdf: any) => {
    const { numPages } = pdf;

    const newPDFPages: PDFPageProps[] = [];

    for (let i = 1; i <= numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 1 });

      const annotations = await page.getAnnotations();

      const newPDFPage: PDFPageProps = {
        pageNumber: i,
        pdfPageWidth: viewport.width,
        pdfPageHeight: viewport.height,
        annotations: annotations.length > 0 ? annotations.map((annotation) => annotation.rect) : [],
      };

      newPDFPages.push(newPDFPage);
    }

    setNumPages(numPages);
    setPageNumber(1);
    setCurrentPDFPage(newPDFPages[0]);
    setPDFPages(newPDFPages);
  };

  const previousDocument = () => {
    setPageNumber(pageNumber - 1);
  };

  const nextDocument = () => {
    setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    if (currentPDFPage && currentPDFPage.pageNumber !== -1) {
      const currentPDFPageWidth = pdfPages[pageNumber - 1].pdfPageWidth;

      if (wrapperSize.width > 0 && currentPDFPageWidth > 0) {
        const newScale = wrapperSize.width / currentPDFPageWidth;

        if (newScale !== scale) {
          setScale(newScale);
        }
      }
    }
  }, [currentPDFPage]);

  useEffect(() => {
    setCurrentPDFPage(pdfPages[pageNumber - 1]);
  }, [pageNumber]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'space-between',
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mb: 2 }}>
        <IconButton
          id="previous-page-button"
          disabled={pageNumber === 1}
          size="small"
          onClick={previousDocument}
        >
          <ChevronLeftIcon fontSize="inherit" />
        </IconButton>

        <Typography sx={{ textAlign: 'center' }} noWrap>
          {t('forms.documentNavigation.page')} {pageNumber} {t('forms.documentNavigation.of')}{' '}
          {numPages}
        </Typography>

        <IconButton
          id="next-page-button"
          disabled={pageNumber === numPages}
          onClick={nextDocument}
          size="small"
        >
          <ChevronRightIcon fontSize="inherit" />
        </IconButton>
      </Box>

      {currentDocument && wrapperSize.width > 0 && (
        <Document file={currentDocument.uri} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} width={wrapperSize.width} />

          {!!stamp && pageNumber === stampPage && (
            <Rnd
              bounds="parent"
              position={{ x: signaturePosition.x * scale, y: signaturePosition.y * scale }}
              size={{ width: width ? width * scale : signatureSize.height, height: height? height * scale : signatureSize.height * scale }}
              onDragStop={handleDragStop}
              // onResizeStop={(e, direction, ref, delta, position) => handleResizeStop(ref, position)}
              lockAspectRatio
              style={{
                border: `1px dashed ${theme.palette.grey[600]}`,
                zIndex: 10,
                cursor: 'move',
                userSelect: 'none',
              }}
            >
              <Box sx={{ width: '100%', height: '100%' }}>
                <img
                  src={stamp}
                  alt="signature"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    pointerEvents: 'none',
                  }}
                />
              </Box>
            </Rnd>
          )}
        </Document>
      )}

      {/* ---- TODO: Mark annotations ----*/}

      {/* {currentPDFPage && */}
      {/*  currentPDFPage.annotations.length > 0 && */}
      {/*  currentPDFPage.annotations.map((annotation, index) => ( */}
      {/*    <Box */}
      {/*      sx={{ */}
      {/*        width: `${(annotation[2] - annotation[0]) * scale}px`, */}
      {/*        height: `${(annotation[3] - annotation[1]) * scale}px`, */}
      {/*        backgroundColor: 'pink', */}
      {/*        position: 'absolute', */}
      {/*        bottom: `${annotation[1] * scale}px`, */}
      {/*        left: `${annotation[0] * scale}px`, */}
      {/*        zIndex: 100, */}
      {/*        opacity: 0.2, */}
      {/*      }} */}
      {/*    /> */}
      {/*  ))} */}
    </Box>
  );
};

export default DocumentsViewerPDFRenderer;
